import CustomLayout from './wrapPageElement';

import './src/styles/main.scss';

//
// Implement the `wrapPageElement` hook to avoid remounting of layout:
// https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
//
export const wrapPageElement = CustomLayout;

//
// Implement the `shouldUpdateScroll` hook avoiding scroll on route change:
// https://www.gatsbyjs.org/docs/browser-apis/#shouldUpdateScroll
//
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
	const savedPosition = getSavedScrollPosition(location);
	const scrollTo = savedPosition || [0, 0];
  	window.scrollTo(...scrollTo);
	return false;
};
