import { useContext } from 'react';
import { Link } from 'gatsby';

import LocaleContext from '../contexts/locale';

import * as styles from './languages.module.scss';

const Languages = () => {
	const { locales, currentLocale, defaultLocale, setCurrentLocale } =
		useContext(LocaleContext);

	const onClick = ({ target: { hreflang: locale } }) => setCurrentLocale(locale);

	return (
		<nav className={styles.wrapper}>
			{locales.map((locale, index) => (
				<div key={index} className={styles.item}>
					<Link
						rel="alternate"
						hrefLang={locale}
						onClick={onClick}
						to={locale !== defaultLocale ? `/${locale}` : '/'}
						className={
							locale !== currentLocale
								? `${styles.link}`
								: `${styles.link} ${styles.linkActive}`
						}>
						{locale}
					</Link>
					<span className={styles.separator} />
				</div>
			))}
		</nav>
	);
};

export default Languages;
