import Layout from './src/components/layout';
import { LocaleProvider } from './src/contexts/locale';

const wrapPageElement = ({ element, props }) => {
	const {
		pageContext: { locale },
	} = props;

	return (
		<LocaleProvider locale={locale}>
			<Layout {...props}>{element}</Layout>
		</LocaleProvider>
	);
};

export default wrapPageElement;
