//
// Scrolls to a dom element smoothly
// 
export const scrollTo = (element) => {
	if (element instanceof HTMLElement) {
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		})
	}
};

//
// Tries to read overall scroll offset
// 
export const getScrollOffsetY = () => {
	return typeof window.pageYOffset === 'number'
		? window.pageYOffset
		: (
			typeof document.documentElement.scrollTop === 'number'
				? document.documentElement.scrollTop
				: document.body.scrollTop
		)
};

//
// Tries to detect resize changes of element
// 
// export const observeResize = (element, callback) => {
// 	if (typeof MutationObserver === 'undefined') {
// 		const eventName = 'DOMSubtreeModified';

// 		document.addEventListener(eventName, callback);
//     	return () => 
//     		document.removeEventListener(eventName, callback);
//     }

//     if (typeof ResizeObserver === 'undefined') {
// 	    const observer = new MutationObserver(callback);
// 	    observer.observe(element, {
// 	        attributes: true,
// 	        childList: true,
// 	        characterData: true,
// 	        subtree: true
// 	    });

// 	    return () => observer.disconnect();
// 	}

// 	const observer = new ResizeObserver(callback);
//     observer.observe(element);

//     return () => observer.disconnect();
// };