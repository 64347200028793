import PropTypes from 'prop-types';
import { marked } from 'marked';

export const Markdown = (props) => {
	const { content } = props;

	props = {
		...props,
		...{
			content: undefined,
		},
	};

	delete props.content;

	return <HTML content={marked(content, props)} />;
};

Markdown.propTypes = {
	content: PropTypes.string.isRequired,
};

export const HTML = (props) => {
	const html = {
		__html: props.content,
	};

	props = {
		...props,
		...{
			content: undefined,
		},
	};

	delete props.content;

	return <div {...props} dangerouslySetInnerHTML={html} />;
};

HTML.propTypes = {
	content: PropTypes.node.isRequired,
};
