import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { usePageBySlug, MENU_ICON_MAP } from '../hooks/use-pages';
import { HTML } from '../components/utils';
import { getScrollOffsetY } from '../utils/dom';

import LocaleContext from '../contexts/locale';
import MetaTags from '../components/meta-tags';

import Footer from './footer';
import Header from './header';
import Languages from './languages';
import * as styles from './layout.module.scss';

//
// LAYOUT
//
const Layout = ({ children, pageContext: { slug, locale } }) => {
	const { setCurrentLocale } = useContext(LocaleContext);
	const [showMenu, setShowMenu] = useState(true);
	const [isFixed, setIsFixed] = useState(false);

	useEffect(() => {
		// note: this is to catch users navigating between
		// different locales using the browser back button
		setCurrentLocale(locale);
	}, [locale /* only run effect when language changes! */]);

	useEffect(() => {
		let prevScrollYPos = getScrollOffsetY();
		let foreLastScrollYPos = prevScrollYPos;
		let recursionDetected = false;

		const handleScroll = (/* event */) => {
			if (recursionDetected !== false) {
				return;
			}

			const scrollYPos = getScrollOffsetY();
			const scrollDelta = scrollYPos - prevScrollYPos;

			// note: this happens if setting a fixed
			// header gets follwed by a disappearing
			// scrollbar as page gets too small, but
			// this would force an infinite loop in
			// browser, as the scroll pos jumps to 0
			recursionDetected = scrollYPos === 0 && foreLastScrollYPos === 0;

			foreLastScrollYPos = prevScrollYPos;
			prevScrollYPos = scrollYPos;

			setShowMenu(scrollDelta < 0);
			setIsFixed(scrollYPos > 0);
		};

		document.addEventListener('scroll', handleScroll, false);

		return () => {
			document.removeEventListener('scroll', handleScroll, false);
		};
	}, [slug /* only run effect on page changes for recursion detection! */]);

	return (
		<div className={styles.wrapper}>
			<Header isFixed={isFixed} showMenu={showMenu} />
			<Languages />
			<main className={styles.main}>{children}</main>
			<Footer />
			<MetaTags page={slug} />
		</div>
	);
};

export default Layout;

//
// PAGE
//
export const Page = ({
	slug,
	body,
	teaser,
	children,
	isFullWidth,
	isTitleHidden,
	isBackgroundHidden,
}) => {
	const { key, title } =
		usePageBySlug(slug) ||
		{
			/* 404 */
		};
	const icon = MENU_ICON_MAP[key];
	const className = [
		styles.page,
		isFullWidth ? styles.pageFullWidth : '',
		isBackgroundHidden ? '' : styles.pageWithBackground,
	].join(' ');

	return (
		<div className={className}>
			{teaser}
			<div className={styles.pageContent}>
				{title && !isTitleHidden && <Title icon={icon} text={title} />}
				{body && <HTML content={body} className={styles.pageBody} />}
				{children}
			</div>
		</div>
	);
};

Page.defaultProps = {
	slug: null,
	body: null,
	teaser: null,
	isFullWidth: false,
	isTitleHidden: false,
	isBackgroundHidden: false,
};

Page.propTypes = {
	slug: PropTypes.string,
	body: PropTypes.string,
	teaser: PropTypes.node,
	isFullWidth: PropTypes.bool,
	isTitleHidden: PropTypes.bool,
	isBackgroundHidden: PropTypes.bool,
};

//
// TITLE
//
export const Title = ({ text, icon: Icon }) => (
	<h1 className={styles.title}>
		{Icon && <Icon className={styles.titleSvg} />}
		<span className={styles.titleText}>{text}</span>
	</h1>
);

Title.defaultProps = {
	text: null,
	icon: null,
};

Title.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.func,
};
