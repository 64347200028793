import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { LogoIcon } from './icons';
import LocaleContext from '../contexts/locale';
import useBlockHeader from '../hooks/use-block-header';
import { usePagesByMenu, MENU_TYPE_MAIN, MENU_ICON_MAP } from '../hooks/use-pages';

import * as styles from './header.module.scss';

//
// HEADER
//
const Header = ({ showMenu, isFixed }) => (
	<header
		className={!isFixed ? `${styles.header}` : `${styles.header} ${styles.headerFixed}`}>
		<Logo isSmall={isFixed} />
		<Address isHidden={isFixed} />
		<Navigation isHidden={isFixed && !showMenu} />
	</header>
);

Header.defaultProps = {
	isFixed: false,
	showMenu: true,
};

Header.propTypes = {
	isFixed: PropTypes.bool.isRequired,
	showMenu: PropTypes.bool.isRequired,
};

export default Header;

//
// LOGO
//
const Logo = ({ isSmall }) => {
	const { currentLocale, defaultLocale } = useContext(LocaleContext);

	return (
		<div className={!isSmall ? `${styles.logo}` : `${styles.logo} ${styles.logoSmall}`}>
			<Link
				to={currentLocale !== defaultLocale ? `/${currentLocale}` : '/'}
				className={styles.logoLink}>
				<LogoIcon className={styles.logoSvg} />
			</Link>
		</div>
	);
};

Logo.defaultProps = {
	isSmall: false,
};

Logo.propTypes = {
	isSmall: PropTypes.bool,
};

//
// ADDRESS
//
const Address = ({ isHidden }) => {
	const { address_lines } = useBlockHeader();

	if (!Array.isArray(address_lines)) {
		return null;
	}

	return (
		<address
			className={
				!isHidden ? `${styles.address}` : `${styles.address} ${styles.addressHidden}`
			}>
			{address_lines.map((line, key) => (
				<div key={key}>{line}</div>
			))}
		</address>
	);
};

Address.defaultProps = {
	isHidden: false,
};

Address.propTypes = {
	isHidden: PropTypes.bool.isRequired,
};

//
// NAVIGATION
//
const Navigation = ({ isHidden }) => (
	<nav className={!isHidden ? `${styles.nav}` : `${styles.nav} ${styles.navHidden}`}>
		{usePagesByMenu(MENU_TYPE_MAIN).map(({ slug, title, key }, index) => {
			const Icon = MENU_ICON_MAP[key];

			return (
				<Link
					to={slug}
					key={index}
					partiallyActive={true}
					className={`${styles.navItem}`}
					activeClassName={styles.navItemActive}>
					<span>
						<Icon className={styles.navItemIconSvg} />
					</span>
					<span className={styles.navItemText}>{title}</span>
				</Link>
			);
		})}
	</nav>
);

Navigation.defaultProps = {
	isHidden: false,
};

Navigation.propTypes = {
	isHidden: PropTypes.bool.isRequired,
};
