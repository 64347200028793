import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SvgLoader } from 'react-svgmt';

import { default as ArrowLeftSVG } from '../icons/arrow-left.svg';
import { default as ArrowRightSVG } from '../icons/arrow-right.svg';
import { default as CloseSVG } from '../icons/close.svg';
import { default as BrandsSVG } from '../icons/brands.svg';
import { default as CulinarySVG } from '../icons/culinary.svg';
import { default as EmailSVG } from '../icons/email.svg';
import { default as HoursSVG } from '../icons/hours.svg';
import { default as InstagramSVG } from '../icons/instagram.svg';
import { default as LoaderSVG } from '../icons/loader.svg';
import { default as LocationsSVG } from '../icons/location.svg';
import { default as LogoSVG } from '../icons/logo.svg';
import { default as PhoneSVG } from '../icons/phone.svg';
import { default as ServiceSVG } from '../icons/service.svg';

import * as styles from './icons.module.scss';

const Icon = ({ icon: IconType, className, ...props }) => {
	const wrapperClassName = useMemo(() => [styles.icon, className].join(' '), [className]);

	return <IconType className={wrapperClassName} {...props} />;
};

Icon.propTypes = {
	className: PropTypes.string,
};

export const ArrowLeftIcon = (props) => <Icon icon={ArrowLeftSVG} {...props} />;

export const ArrowRightIcon = (props) => <Icon icon={ArrowRightSVG} {...props} />;

export const BrandsIcon = (props) => <Icon icon={BrandsSVG} {...props} />;

export const CloseIcon = (props) => <Icon icon={CloseSVG} {...props} />;

export const CulinaryIcon = (props) => <Icon icon={CulinarySVG} {...props} />;

export const EmailIcon = (props) => <Icon icon={EmailSVG} {...props} />;

export const HoursIcon = (props) => <Icon icon={HoursSVG} {...props} />;

export const InstagramIcon = (props) => <Icon icon={InstagramSVG} {...props} />;

export const LoaderIcon = (props) => <Icon icon={LoaderSVG} {...props} />;

export const LocationsIcon = (props) => <Icon icon={LocationsSVG} {...props} />;

export const LogoIcon = (props) => <Icon icon={LogoSVG} {...props} />;

export const PhoneIcon = (props) => <Icon icon={PhoneSVG} {...props} />;

export const ServiceIcon = (props) => <Icon icon={ServiceSVG} {...props} />;

export const SVGIcon = ({ path, className, ...props }) => {
	const wrapperClassName = useMemo(() => [styles.icon, className].join(' '), [className]);

	return <SvgLoader path={path} className={wrapperClassName} {...props} />;
};

SVGIcon.defaultProps = {
	path: null,
	className: '',
};

SVGIcon.propTypes = {
	path: PropTypes.string.isRequired,
	className: PropTypes.string,
};
