import { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import LocaleContext from '../contexts/locale';

import {
	PhoneIcon,
	LocationsIcon,
	BrandsIcon,
	CulinaryIcon,
	ServiceIcon,
	HoursIcon
} from '../components/icons';

export const MENU_TYPE_MAIN = 'main';
export const MENU_TYPE_MISC = 'misc';
export const MENU_ICON_MAP = {
	'phone': PhoneIcon,
	'locations': LocationsIcon,
	'brands': BrandsIcon,
	'culinary': CulinaryIcon,
	'service': ServiceIcon,
	'hours': HoursIcon
};

const all = [];
const keyMap = {};
const menuMap = {};
const slugMap = {};
const localeMap = {};

export const usePageByKey = (key) => {
	useInvokeQueryAndMapData();

	const {
		currentLocale
	} = useContext(LocaleContext);

	if (key in keyMap[currentLocale]) {
		return keyMap[currentLocale][key];
	}

	return null;
};

export const usePagesByMenu = (menu) => {
	const {
		currentLocale
	} = useContext(LocaleContext);
	const defaultPages = useInvokeQueryAndMapData();

	if (menu in menuMap) {
		return (
			menuMap[menu] || defaultPages
		).filter(
			({ locale }) => locale === currentLocale
		)
	}

	return [];
};

export const usePageBySlug = (slug) => {
	useInvokeQueryAndMapData();

	if (slug in slugMap) {
		return slugMap[slug];
	}

	return null;
};

export const usePageSlugMap = () => {
	useInvokeQueryAndMapData();

	return slugMap;
};

const useInvokeQueryAndMapData = () => {
	const {
		allSitePage: {
			edges: pages
		}
	} = useStaticQuery(query);

	const {
		currentLocale
	} = useContext(LocaleContext);

	const mapItem = ({
		node: {
			context			
		}
	}) => context;

	if (all.length === 0) {
		for (let page of pages) {
			let {
				node: {
					context: {
						key,
						slug,
						menu,
						locale
					}
				} 
			} = page;
			
			const item = mapItem(page);

			localeMap[locale] = localeMap[locale] || [];
			keyMap[locale] = keyMap[locale] || {};
			menuMap[menu] = menuMap[menu] || [];

			localeMap[locale].push(item);
			menuMap[menu].push(item);

			keyMap[locale][key] = item;
			slugMap[slug] = item;
			all.push(item);
		}
	}

	return localeMap[currentLocale] || [];
}

export default useInvokeQueryAndMapData;

const query = graphql`
	query usePagesQuery {
		allSitePage(
			sort: {
				order: ASC
				fields: [context___order]
			}
			filter: {
				context: {
					type: {
						in: [
							"page"
							"location"
							"job"
						]
					}
				}
			}
		) {
			edges {
				node {
					context {
						key
						type
						menu
						order						
						slug
						title
						locale
						parent
						description
					}
				}
			}
		}
	}
`;
