// extracted by mini-css-extract-plugin
export var main = "layout-module--main--ay8oo";
export var page = "layout-module--page--ch2c9";
export var pageBody = "layout-module--page-body--Oiivz";
export var pageContent = "layout-module--page-content--W-kzN";
export var pageFullWidth = "layout-module--page--full-width--DNjdM";
export var pageWithBackground = "layout-module--page--with-background--cbgnG";
export var title = "layout-module--title--vfWcb";
export var titleSvg = "layout-module--title-svg--dFl+Q";
export var titleText = "layout-module--title-text--Tz+NF";
export var wrapper = "layout-module--wrapper--5UXpi";