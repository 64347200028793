exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-brands-jsx": () => import("./../../../src/templates/brands.jsx" /* webpackChunkName: "component---src-templates-brands-jsx" */),
  "component---src-templates-culinary-jsx": () => import("./../../../src/templates/culinary.jsx" /* webpackChunkName: "component---src-templates-culinary-jsx" */),
  "component---src-templates-hours-jsx": () => import("./../../../src/templates/hours.jsx" /* webpackChunkName: "component---src-templates-hours-jsx" */),
  "component---src-templates-imprint-jsx": () => import("./../../../src/templates/imprint.jsx" /* webpackChunkName: "component---src-templates-imprint-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-job-jsx": () => import("./../../../src/templates/job.jsx" /* webpackChunkName: "component---src-templates-job-jsx" */),
  "component---src-templates-jobs-jsx": () => import("./../../../src/templates/jobs.jsx" /* webpackChunkName: "component---src-templates-jobs-jsx" */),
  "component---src-templates-location-jsx": () => import("./../../../src/templates/location.jsx" /* webpackChunkName: "component---src-templates-location-jsx" */),
  "component---src-templates-locations-jsx": () => import("./../../../src/templates/locations.jsx" /* webpackChunkName: "component---src-templates-locations-jsx" */),
  "component---src-templates-phone-jsx": () => import("./../../../src/templates/phone.jsx" /* webpackChunkName: "component---src-templates-phone-jsx" */),
  "component---src-templates-privacy-jsx": () => import("./../../../src/templates/privacy.jsx" /* webpackChunkName: "component---src-templates-privacy-jsx" */),
  "component---src-templates-service-jsx": () => import("./../../../src/templates/service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */)
}

