// extracted by mini-css-extract-plugin
export var address = "header-module--address--+NLl+";
export var addressHidden = "header-module--address--hidden--T8btC";
export var header = "header-module--header--knWBH";
export var headerFixed = "header-module--header--fixed--+nf8c";
export var logo = "header-module--logo--y97xu";
export var logoLink = "header-module--logo-link--UTD9v";
export var logoSmall = "header-module--logo--small--GJdmf";
export var logoSvg = "header-module--logo-svg--ESXgl";
export var nav = "header-module--nav--piaz0";
export var navHidden = "header-module--nav--hidden--fUTRf";
export var navItem = "header-module--nav-item--2oc8C";
export var navItemActive = "header-module--nav-item--active--XQSRj";
export var navItemIconSvg = "header-module--nav-item-icon-svg--HgHZN";
export var navItemText = "header-module--nav-item-text--iqQfk";