import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { usePageBySlug, usePageSlugMap } from '../hooks/use-pages';
import { t } from '../utils/i18n';
import LocaleContext from '../contexts/locale';
import useBlockGeneral from '../hooks/use-block-general';

const Metatags = ({ page }) => {
	let { title, parent, description, currentPage } =
		usePageBySlug(page) ||
		{
			/* 404 */
		};

	const { currentLocale: locale } = useContext(LocaleContext);

	const slugMap = usePageSlugMap();

	const { company_name } = useBlockGeneral();

	const titles = [title];

	const pageNumber = `${t('PAGE')} ${currentPage}`;
	titles.push(currentPage > 1 ? pageNumber : '');

	while (parent) {
		const page = slugMap[parent];
		titles.push(page.title);
		parent = page.parent;
	}

	const companyName = t('COMPANY_NAME');
	titles.push(`${company_name || companyName}`);

	const notFoundText = t('NOTFOUND_TEXT');
	title = titles.filter(Boolean).join(' | ');
	description = description || notFoundText;

	return (
		<Helmet>
			<html lang={locale} />
			<title>{title}</title>
			<meta name="robots" content="index, follow" />
			<meta name="description" content={description} />
			<meta name="og:locale" content={locale} />
			<meta name="og:type" content="website" />
			<meta name="og:title" content={title} />
			<meta name="og:description" content={description} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
			<link rel="manifest" href="/site.webmanifest" />
			<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ff6316" />
			<meta name="msapplication-TileColor" content="#191919" />
			<meta name="theme-color" content="#ffffff" />
		</Helmet>
	);
};

Metatags.defaultProps = {
	page: null,
};

Metatags.propTypes = {
	page: PropTypes.string,
};

export default Metatags;
