import { Link } from 'gatsby';

import { SVGIcon } from './icons';
import useBlockFooter from '../hooks/use-block-footer';
import { usePagesByMenu, MENU_TYPE_MISC } from '../hooks/use-pages';

import * as styles from './footer.module.scss';

//
// FOOTER
//
const Footer = () => {
	const { copyright, social_media } = useBlockFooter();

	const year = new Date().getFullYear();

	return (
		<footer className={styles.wrapper}>
			<div className={styles.container}>
				{Array.isArray(social_media) &&
					social_media.map(({ icon: { publicURL: src }, link }, index) => (
						<div key={index} className={styles.item}>
							<a
								href={link}
								target="_blank"
								className={`${styles.link} ${styles.linkIcon}`}>
								<SVGIcon path={src} className={styles.svg} />
							</a>
							<span className={styles.separator} />
						</div>
					))}

				{usePagesByMenu(MENU_TYPE_MISC).map((page) => (
					<div key={page.slug} className={styles.item}>
						<Link
							to={page.slug}
							className={styles.link}
							activeClassName={styles.linkActive}>
							{page.title}
						</Link>
						<span className={styles.separator} />
					</div>
				))}

				<div key="copyright" className={styles.item}>
					&copy; {copyright} {year}
				</div>
			</div>
		</footer>
	);
};

export default Footer;
